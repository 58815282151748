import { combineReducers } from 'redux'
import dataStoreReducer from './DataStoreReducer'
import errorPoolReducer from './ErrorPoolReducer'
import { NotificationsReducer } from './NotificationsReducer'
import userReducer from './UserReducer'
import * as slices from './slices'
import resourcesQueue from './resourcesQueue'
import bookmarksSlice from './bookmarksSlice'
// Combine all the sub reducers
const rootReducer = combineReducers({
  user: userReducer,
  dataStore: dataStoreReducer,
  notificationStore: NotificationsReducer,
  errorPool: errorPoolReducer,
  userList: slices.userListSlice.reducer,
  groupList: slices.groupListSlice.reducer,
  idpList: slices.idpListSlice.reducer,
  googleIdpList: slices.googleIdpListSlice.reducer,
  credentialsList: slices.credentialsListSlice.reducer,
  deviceList: slices.deviceListSlice.reducer,
  serverGroupList: slices.serverGroupListSlice.reducer,
  applicationList: slices.applicationListSlice.reducer,
  accountList: slices.accountListSlice.reducer,
  appRolesList: slices.appRolesListSlice.reducer,
  approvalReqList: slices.approvalReqListSlice.reducer,
  // webProxyActivityLogs: webProxyActivityLogsSlice.reducer,
  // sshActivityLogs: sshActivityLogsSlice.reducer,
  userAccountsList: slices.userAccountsListSlice.reducer,
  recordingList: slices.recordingListSlice.reducer,
  serverList: slices.serverListSlice.reducer,
  // sshAccessList: sshAccessListSlice.reducer,
  // roleAccessList: roleAccessListSlice.reducer,
  credentialTypeList: slices.credentialTypeSlice.reducer,
  credentialInstanceList: slices.credentialInstanceSlice.reducer,
  policyList: slices.policySlice.reducer,
  paaPolicyList: slices.paaPolicySlice.reducer,
  // eventLogList: eventLogSlice.reducer,
  // proxyAuditLogsList: proxyAuditLogsSlice.reducer,
  resourceActions: slices.resourceActionsSlice.reducer,
  resourceKindGrants: slices.resourceKindGrantsSlice.reducer,
  proxyList: slices.proxiesSlice.reducer,
  slackIntegrationList: slices.slackIntegrations.reducer,
  jiraIntegrationsList: slices.jiraIntegrations.reducer,
  slackChannelList: slices.slackChannel.reducer,
  jiraProjectList: slices.jiraProject.reducer,
  userpreferences: slices.userpreferencesSlice.reducer,
  projects: slices.projectsSlice.reducer,
  medusaNodes: slices.medusaNodesSlice.reducer,
  tenantprofiles: slices.tenantprofilesSlice.reducer,
  iamActions: slices.iamActionsSlice.reducer,
  awsResources: slices.awsResourcesSlice.reducer,
  gcpResources: slices.gcpResources.reducer,
  awsCloudActions: slices.awsCloudActions.reducer,
  gcpCloudActions: slices.gcpCloudActions.reducer,
  azureCloudActions: slices.azureCloudActions.reducer,
  databases: slices.databaseSlice.reducer,
  serviceAccounts: slices.serviceAccountsSlice.reducer,
  kubeNamespaces: slices.kubeNamespacesSlice.reducer,
  azureResources: slices.azureResourcesSlice.reducer,

  tenantRiskProfileListing: slices.tenantRiskProfileListingSlice.reducer,
  tenantRiskSummary: slices.tenantRiskSummarySlice.reducer,
  accountRiskProfileListing: slices.accountRiskProfileListingSlice.reducer,
  accountRiskSummary: slices.accountRiskSummarySlice.reducer,
  tenantAccountList: slices.tenantAccountsListSlice.reducer,

  accountGraph: slices.accountGraphSlice.reducer,

  resourcesQueue: resourcesQueue,
  bookmarks: bookmarksSlice
})

export * from './slices'
export default rootReducer
